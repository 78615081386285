.navigation_link {
  &__wrapper {
    list-style-type: none;
    cursor: pointer;
    width: 100%;
    max-width: 480px;
    color: #000;
    &:hover {
      background: rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      padding: 0 10px;
    }
    &:last-child {
      .navigation_link__body {
        div {
          .navigation_link__content {
            border-bottom: none;
          }
        }
      }
    }
    @media screen and (max-width: 470px) {
      max-width: 350px;
    }
  }
  &__body {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 16.12px;
    line-height: 20px;
    letter-spacing: 0.242px;
    color: inherit;
    margin-bottom: 0;

    &_wrapper {
      padding: 0;
      flex: 1 1 auto;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    width: 100%;
    max-width: 415px;
    display: flex;
    align-items: center;
    padding: 10px 0 9px;
    @media screen and (max-width: 470px) {
      flex: 1 1 auto;
      width: auto;
      max-width: 285px;
    }
    @media screen and (max-width: 320px) {
      flex: 1 1;
    }
  }
  &__arrow {
    padding: 0;
  }
  &__logo {
    &_wrapper {
      padding: 0 15px 0 10px;
    }
  }
  &__active {
    background: black;
    border-radius: 16px;
    padding: 0 10px;
    color: #fff;
    &:hover {
      color: #000;
    }
  }
}